<template>
  <v-container id="data-tables-view" fluid tag="section" ref="pageTop">
    <material-card
      icon="mdi-calendar-month"
      icon-small
      color="primary"
      title="Bookings"
    >
      <v-card-text>
        <v-col class="text-right ma-0 pa-0">
          <app-btn @click="dialog = true">
            <v-icon>mdi-account-plus-outline</v-icon> Add New Job
          </app-btn>
        </v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          single-line
          label="Search records"
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />
        <!-- show-expand -->
        <v-data-table
          :headers="new_headers"
          :items="bookings_data"
          :options.sync="options"
          :server-items-length="totalRecords"
          :loading="loading"
          :items-per-page="5"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [5, 10, 15],
          }"
          show-expand
          single-expand
          :expanded.sync="expanded"
          item-key="id"
          @item-expanded="(data) => getFareDetails(data)"
        >
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              :color="getStatus(item.status).color"
              text-color="white"
              v-if="getStatus(item.status)"
            >
              {{ getStatus(item.status).text }}</v-chip
            >
          </template>
          <template v-slot:top>
            <BookingDialog :isOpen="dialog" @closeDialog="dialog = false" />
            <v-dialog v-model="dialogTripDetails" max-width="950px">
              <v-card>
                <v-card-title> Trip Details </v-card-title>
                <v-card-text>
                  <v-row class="mt-2">
                    <v-col>
                      <PolyLineMap
                        width="650"
                        height="500"
                        :path="[
                          { lat: -33.7307419, lng: 150.9258165 },
                          { lat: -33.7623551, lng: 150.9781278 },
                          { lat: -33.7623551, lng: 150.9781278 },
                          { lat: -33.788357, lng: 151.0355983 },
                        ]"
                      />
                    </v-col>
                    <v-col>
                      <v-timeline align-top dense>
                        <v-timeline-item
                          color="primary"
                          small
                          v-for="(status, i) in selectedTrip.statuses"
                          :key="i"
                        >
                          <strong>{{ getStatus(status.status).text }}</strong>
                          <div class="text-caption">
                            {{ formatDate(status.time) }}
                          </div>
                        </v-timeline-item>
                      </v-timeline>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogTripDetails = false"
                  >
                    Close
                  </v-btn>

                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this booking?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- <dialog-drag id="dialog-1" title="Map" :options="dialogOptions">
              <p>Test dialog</p>
            </dialog-drag> -->
          </template>
          <template v-slot:[`item.totalFares`]="{ item }">
            <div>{{ "$" + item.totalFares }}</div>
          </template>
          <template v-slot:[`item.totalSpecialFares`]="{ item }">
            <div>{{ "$" + item.totalSpecialFares }}</div>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            <div>{{ formatDate(item.date) }}</div>
          </template>
          <template v-slot:[`item.pickedUpTime`]="{ item }">
            {{ getpickedUpTime(item.statuses) }}
          </template>
          <template v-slot:[`item.droppedOffTime`]="{ item }">
            {{ getDroppedOffTime(item.statuses) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="getStatus(item.status).text === 'New'">
              <v-icon medium color="info" class="mr-2" @click="sendJob(item)">
                mdi-send
              </v-icon>
              <v-icon medium color="error" @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </div>
            <div v-else>
              <v-icon
                medium
                color="info"
                @click="
                  selectedTrip = Object.assign({}, item);
                  dialogTripDetails = true;
                "
              >
                mdi-map-search
              </v-icon>
            </div>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="primary pa-1">
              <v-data-table
                :headers="fare_header"
                :items="item.specialFares"
                :footer-props="{
                  showFirstLastPage: true,
                }"
              >
                <template v-slot:[`item.fare`]="{ item }">
                  {{ "$" + centsToDollor(item.fare) }}
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import JobDataService from "../services/JobDataService";
import moment from "moment";
import BookingDialog from "./components/BookingDialog.vue";
import PolyLineMap from "./components/PolyLineMap.vue";

export default {
  name: "DataTablesView",
  components: { BookingDialog, PolyLineMap },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogTripDetails: false,
    expanded: [],
    options: {},
    totalRecords: 0,
    loading: false,
    new_headers: [
      {
        text: "Booking ID",
        align: "start",
        value: "id",
      },
      { text: "Status", align: "center", value: "status" },
      { text: "From", value: "fromCity" },
      { text: "To", value: "toCity" },
      { text: "Driver", value: "driver.driverNo" },
      { text: "Vehicle", value: "vehicle.vehicleNo" },
      { text: "Total Fare", value: "totalFares" },
      { text: "Special Fare", value: "totalSpecialFares" },
      { text: "Created at", value: "date" },
      { text: "Picked Up Time", value: "pickedUpTime" },
      { text: "Dropped Off Time", value: "droppedOffTime" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    fare_header: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      { text: "Fare", value: "fare" },
    ],
    bookings_data: [],
    editedIndex: -1,
    editedItem: {
      fromCity: "",
      toCity: "",
      fromLatitude: 0,
      fromLongitude: 0,
      toLatitude: 0,
      toLongitude: 0,
      customerFirstName: "",
      customerLastName: "",
      customerUsername: "",
      customerEmail: "",
      vehicleRange: 5,
      note: "",
    },
    defaultItem: {
      fromCity: "",
      toCity: "",
      fromLatitude: 0,
      fromLongitude: 0,
      toLatitude: 0,
      toLongitude: 0,
      customerFirstName: "",
      customerLastName: "",
      customerUsername: "",
      customerEmail: "",
      vehicleRange: 5,
      note: "",
    },
    search: undefined,
    searchTimmer: null,
    selectedTrip: {},
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Job" : "Edit Job";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getAllJobs("");
      },
      deep: true,
    },
    search: {
      handler(val) {
        clearTimeout(this.searchTimmer);
        this.searchTimmer = setTimeout(() => {
          this.getAllJobs(val);
        }, 1500);
      },
    },
  },

  created() {
    this.$locationHub.$on("newJob", this.newJobNotification);
    this.$locationHub.$on("JobStatusChanged", this.onJobStatusChanged);
  },
  beforeDestroy() {
    this.$locationHub.$on("newJob", this.newJobNotification);
    this.$locationHub.$on("JobStatusChanged", this.onJobStatusChanged);
  },

  methods: {
    getAllJobs(search) {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var data = {
        skip: itemsPerPage * (page - 1),
        take: itemsPerPage,
        searchValue: search,
      };
      this.$http
        .post("/Job/GetAllBySearch", data)
        .then((response) => {
          this.loading = false;
          this.bookings_data = response.data.jobs;
          this.totalRecords = response.data.total;
        })
        .catch(() => {});
    },
    editItem(item) {
      this.editedIndex = this.bookings_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.bookings_data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      let index = this.editedIndex;
      this.$http
        .delete(`/Job/${this.editedItem.id}`)
        .then((response) => {
          if (response.data.status === "success") {
            this.bookings_data.splice(index, 1);
            this.$toast.success("Booking deleted successfully.", "Success", {
              position: "topRight",
            });
          } else {
            this.$toast.error(
              "Something went wrong while deleting the booking.",
              "Error",
              {
                position: "topRight",
              }
            );
          }
        })
        .catch(() => {});

      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    newJobNotification() {
      console.log("newJobNotification");
      this.getAllJobs("");
    },
    sendJob(data) {
      JobDataService.sendJob(data.id).then((response) => {
        if (response.status === 200) {
          this.$toast.success("Job sent successfully.", "Success", {
            position: "topRight",
          });
        }
      });
      //console.log(data);
    },
    formatDate(dateTime) {
      if (dateTime) {
        return moment.utc(dateTime).local().format("MMMM Do YYYY, h:mm:ss a");
      } else {
        return "";
      }
    },
    getStatus(id) {
      switch (id) {
        case 1:
        case 2:
        case 3:
          return {
            id: id,
            color: "#eb8e55",
            text: "New",
          };
        case 4:
          return {
            id: id,
            color: "#f6c546",
            text: "Accepted",
          };
        case 5:
          return {
            id: id,
            color: "#f6f356",
            text: "ToPickup",
          };
        case 6:
          return {
            id: id,
            color: "#7ecd6e",
            text: "PickedUp",
          };
        case 7:
          return {
            id: id,
            color: "#a18406",
            text: "ToDropOff",
          };
        case 8:
          return {
            id: id,
            color: "#088fc9",
            text: "DroppedOff",
          };
        case 9:
          return {
            id: id,
            color: "#ff7878",
            text: "Cancelled",
          };
        case 10:
          return {
            id: id,
            color: "#ff0101",
            text: "Rejected",
          };
        case 11:
          return {
            id: id,
            color: "#f0a501",
            text: "Recall",
          };
        default:
          return {
            id: 0,
            color: "",
            text: "",
          };
      }
    },
    getDroppedOffTime(statuses) {
      if (statuses) {
        let status = statuses.find((x) => x.status === 8);
        if (status) {
          return this.formatDate(status.time);
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    getpickedUpTime(statuses) {
      if (statuses) {
        let status = statuses.find((x) => x.status === 6);
        if (status) {
          return this.formatDate(status.time);
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    onJobStatusChanged(data) {
      // let index = this.bookings_data.findIndex((x) => x.id === data.jobId);
      // let job = this.bookings_data[index];
      // job.status = data.status;
      // this.bookings_data.splice(index, 1, job);
      console.log("onJobStatusChanged");
      this.getAllJobs("");
    },
    centsToDollor(cents) {
      if (cents) {
        return parseFloat(cents / 100).toFixed(2);
      } else {
        return 0;
      }
    },
    getFareDetails(data) {
      console.log(data);
      if (data.value) {
        let index = data.item.specialFares.findIndex(
          (x) => x.name === "Flag fall"
        );
        if (index === -1) {
          data.item.specialFares.unshift({
            name: "Flag fall",
            fare: data.item.flagFall,
          });
          data.item.specialFares.unshift({
            name: "Time Fare",
            fare: data.item.deviceTripDuration * data.item.timeRate,
          });
          data.item.specialFares.unshift({
            name: "Distance Fare",
            fare:
              (data.item.deviceTripDistance / 1000) * data.item.distanceRate,
          });
        }
      }
    },
  },
};
</script>