import http from './http-common'

class JobDataService {
    get(id) {
        return http.get(`/Job/${id}`)
    }

    getAll() {
        return http.get('/Job')
    }

    sendJob(id) {
        return http.get(`/Job/sendJob/${id}`)
    }

    create(data) {
        return http.post('/Job', data)
    }

    update(data) {
        return http.patch('/colour/update', data)
    }

    delete(id) {
        return http.get('/colour/delete/', {
            params: {
                Id: id
            }
        })
    }
}

export default new JobDataService()