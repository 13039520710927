<template>
  <v-dialog v-model="dialog" max-width="850px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="bookingForm">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="editedItem.pickContactNo"
                  label="Pick Contact No"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="editedItem.customerFirstName"
                  label="Customer FirstName"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="editedItem.customerLastName"
                  label="Customer LastName"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="editedItem.customerEmail"
                  label="Customer Email"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-combobox
                  v-model="editedItem.fromCity"
                  :search-input.sync="fromCity"
                  :items="fromCityPredictions"
                  return-object
                  item-text="address"
                  label="From"
                  :rules="[rules.required]"
                ></v-combobox>
              </v-col>
              <v-col cols="12" md="6">
                <v-combobox
                  v-model="editedItem.toCity"
                  :search-input.sync="toCity"
                  :items="toCityPredictions"
                  return-object
                  item-text="address"
                  label="To"
                  :rules="[rules.required]"
                ></v-combobox>
              </v-col>

              <v-col cols="12" md="6">
                <div><label>Pickup Date:</label></div>
                <v-date-picker v-model="pickupDate"></v-date-picker>
              </v-col>
              <v-col cols="12" md="6">
                <div><label>Pickup Time:</label></div>
                <v-time-picker
                  format="ampm"
                  v-model="pickupTime"
                ></v-time-picker>
              </v-col>

              <v-col cols="12" md="6">
                <v-switch v-model="isVehicleRange" label="Driver/km"></v-switch>
              </v-col>
              <v-col cols="12" md="6" v-if="isVehicleRange">
                <v-combobox
                  v-model="editedItem.vehicleRange"
                  :items="vehicleRange"
                  label="Vehicle Range (Km)"
                />
              </v-col>
              <v-col cols="12" md="6" v-if="!isVehicleRange">
                <v-autocomplete
                  v-model="editedItem.vehicleId"
                  :items="activeVehicles"
                  item-text="vehicleNo"
                  item-value="id"
                  label="Vehicle"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-switch
                  v-model="editedItem.hasBookingFee"
                  label="Booking Fee"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="6">
                <v-switch v-model="isExtraFields" label="etc"></v-switch>
              </v-col>
              <v-col cols="12" md="6" v-if="isExtraFields">
                <v-autocomplete
                  v-model="editedItem.vehicleTypeId"
                  :items="vehicleTypes"
                  item-text="name"
                  item-value="vehicleTypeId"
                  label="Car Type"
                />
              </v-col>
              <v-col cols="12" md="6" v-if="isExtraFields">
                <v-text-field
                  v-model="editedItem.noOfPassengers"
                  label="No of Passengers"
                />
              </v-col>

              <v-col cols="12" md="6" v-if="isExtraFields">
                <v-text-field
                  v-model="editedItem.noOfLuckages"
                  label="No of Luggages"
                />
              </v-col>
              <v-col cols="12" v-if="isExtraFields">
                <v-text-field
                  v-model="editedItem.dropContactNo"
                  label="DropOff Contact No"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="editedItem.note" label="Add Note" />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
export default {
  name: "BookingDialog",
  props: ["isOpen"],
  data: () => ({
    dialog: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    editedItem: {
      fromCity: "",
      toCity: "",
      fromLatitude: 0,
      fromLongitude: 0,
      toLatitude: 0,
      toLongitude: 0,
      customerFirstName: "",
      customerLastName: "",
      customerUsername: "",
      customerEmail: "",
      vehicleRange: 5,
      note: "",
      hasBookingFee: true,
    },
    defaultItem: {
      fromCity: "",
      toCity: "",
      fromLatitude: 0,
      fromLongitude: 0,
      toLatitude: 0,
      toLongitude: 0,
      customerFirstName: "",
      customerLastName: "",
      customerUsername: "",
      customerEmail: "",
      vehicleRange: 5,
      note: "",
      hasBookingFee: true,
    },
    fromCity: "",
    toCity: "",
    pickupDate: "",
    pickupTime: "",
    vehicleTypes: [],
    vehicleRange: [5, 10, 15, 20],
    isVehicleRange: true,
    isExtraFields: false,
    fromCityPredictions: [],
    toCityPredictions: [],
    fromCityTimer: "",
    toCityTimer: "",
    contactNoTimer: "",
    activeVehicles: [],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Job" : "Edit Job";
    },
    getPickupContactNo() {
      return this.editedItem.pickContactNo;
    },
  },
  watch: {
    isOpen: {
      handler(val) {
        if (val) {
          this.openDialog();
        } else {
          this.close();
        }
      },
    },
    dialog(val) {
      val || this.close();
    },
    fromCity: {
      handler(val) {
        if (val) {
          clearTimeout(this.fromCityTimer);
          this.fromCityTimer = setTimeout(() => {
            this.searchLocation(val).then(
              (result) => {
                this.fromCityPredictions = result;
              },
              () => {
                this.fromCityPredictions = [];
              }
            );
          }, 1200);
        }
      },
    },
    toCity: {
      handler(val) {
        if (val) {
          clearTimeout(this.toCityTimer);
          this.toCityTimer = setTimeout(() => {
            this.searchLocation(val).then(
              (result) => {
                this.toCityPredictions = result;
              },
              () => {
                this.toCityPredictions = [];
              }
            );
          }, 1200);
        }
      },
    },
    isExtraFields: {
      handler(val) {
        if (
          val === true &&
          (!this.vehicleTypes || this.vehicleTypes.length === 0)
        ) {
          this.getVehicleTypes();
        }
      },
    },
    isVehicleRange: {
      handler(val) {
        if (
          val === false &&
          (!this.activeVehicles || this.activeVehicles.length === 0)
        ) {
          this.getAllVehicles();
        }
      },
    },
    getPickupContactNo: {
      handler(val) {
        if (val && val.length >= 10) {
          clearTimeout(this.contactNoTimer);
          this.contactNoTimer = setTimeout(() => {
            this.findCustomer(val);
            this.editedItem.dropContactNo = this.editedItem.pickContactNo;
            this.editedItem.customerTelephone = this.editedItem.pickContactNo;
          }, 1200);
        }
      },
      deep: true,
    },
  },
  methods: {
    openDialog() {
      this.pickupDate = moment().format("YYYY-MM-DD");
      this.pickupTime = moment().format("HH:mm:ss");
      this.dialog = true;
    },
    getAllVehicles() {
      this.$http
        .get("/Vehicle/GetAllActiveVehiclesIdAndName")
        .then((response) => {
          this.activeVehicles = response.data;
        })
        .catch(() => {});
    },
    close() {
      this.$emit("closeDialog");
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    findCustomer(contactNo) {
      this.$http
        .get("/Customer/GetLastJobInfo", { params: { mobile: contactNo } })
        .then((response) => {
          if (response.data) {
            this.editedItem.customerFirstName = response.data.profile.firstName;
            this.editedItem.customerLastName = response.data.profile.lastName;
            this.editedItem.customerEmail = response.data.email;
            this.editedItem.fromCity = response.data.job.fromCity;
            this.editedItem.toCity = response.data.job.toCity;
            this.editedItem.fromLatitude = response.data.job.fromLatitude;
            this.editedItem.fromLongitude = response.data.job.fromLongitude;
            this.editedItem.toLatitude = response.data.job.toLatitude;
            this.editedItem.toLongitude = response.data.job.toLongitude;
            if (response.data.job.noOfPassengers > 0) {
              this.editedItem.noOfPassengers = response.data.job.noOfPassengers;
            }
            if (response.data.job.noOfLuckages > 0) {
              this.editedItem.noOfLuckages = response.data.job.noOfLuckages;
            }
            if (response.data.job.vehicleTypeId) {
              this.editedItem.vehicleTypeId = response.data.job.vehicleTypeId;
            }
          }
        })
        .catch(() => {});
    },
    async save() {
      if (this.$refs.bookingForm.validate()) {
        try {
          console.log(this.editedItem);
          let fromCity = this.editedItem.fromCity;
          if (typeof fromCity === "object") {
            if (fromCity.fromGoogle) {
              this.editedItem.fromCity = fromCity.address;
              let fromCityCoors = await this.getCoordinates(fromCity.placeId);
              this.editedItem.fromLatitude = fromCityCoors.lat;
              this.editedItem.fromLongitude = fromCityCoors.lng;
            } else {
              this.editedItem.fromCity = fromCity.address;
              this.editedItem.fromLatitude = fromCity.latitude;
              this.editedItem.fromLongitude = fromCity.longitude;
            }
          }

          let toCity = this.editedItem.toCity;
          if (typeof toCity === "object") {
            if (toCity.fromGoogle) {
              this.editedItem.toCity = toCity.address;
              let toCityCoors = await this.getCoordinates(toCity.placeId);
              this.editedItem.toLatitude = toCityCoors.lat;
              this.editedItem.toLongitude = toCityCoors.lng;
            } else {
              this.editedItem.toCity = toCity.address;
              this.editedItem.toLatitude = toCity.latitude;
              this.editedItem.toLongitude = toCity.longitude;
            }
          }

          this.editedItem.pickupTime =
            this.pickupDate + "T" + this.pickupTime + ":00";

          this.$http
            .post("/Job", this.editedItem)
            .then((response) => {
              if (response.data.status === "success") {
                this.$toast.success("Booking successfull.", "Success", {
                  position: "topRight",
                });
              } else {
                this.$toast.error("Booking failed.", "Error", {
                  position: "topRight",
                });
              }
            })
            .catch(() => {});
        } catch (error) {
          console.log(error);
        }
        this.close();
      }
    },
    async searchLocation(val) {
      try {
        let response = await this.$http.post("/BaseStation/SearchPlace", {
          searchValue: val,
          take: 5,
        });

        if (response.data.success) {
          if (response.data.data && response.data.data.length > 0) {
            return Promise.resolve(response.data.data);
          } else {
            return this.searchGoogleLocation(val);
          }
        } else {
          return this.searchGoogleLocation(val);
        }
      } catch (error) {
        console.log(error);
        return this.searchGoogleLocation(val);
      }
    },
    async searchGoogleLocation(val) {
      return new Promise((resolve, reject) => {
        try {
          let service = new google.maps.places.AutocompleteService();
          service.getPlacePredictions(
            {
              input: val,
              componentRestrictions: { country: "au" },
            },
            (predictions, status) => {
              // console.log(predictions, status);
              if (status !== "OK") {
                reject(status);
              }
              if (predictions && predictions.length > 0) {
                let result = predictions.map((res) => {
                  return {
                    fromGoogle: true,
                    placeId: res.place_id,
                    address: res.description,
                  };
                });
                resolve(result);
              } else {
                resolve([]);
              }
            }
          );
        } catch (error) {
          resolve([]);
        }
      });
    },
    async getCoordinates(placeId) {
      return new Promise((resolve, reject) => {
        try {
          let geocoder = new google.maps.Geocoder();
          geocoder.geocode(
            {
              placeId: placeId,
            },
            (responses, status) => {
              if (status !== "OK") {
                reject(status);
              }
              if (responses && responses.length > 0) {
                let lat = responses[0].geometry.location.lat();
                let lng = responses[0].geometry.location.lng();
                resolve({ lat, lng });
              }
            }
          );
        } catch (error) {
          reject(error);
        }
      });
    },
    getVehicleTypes() {
      this.$http
        .get("/VehicleType")
        .then((response) => {
          this.vehicleTypes = response.data;
        })
        .catch(() => {});
    },
  },
};
</script>