var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{ref:"pageTop",attrs:{"id":"data-tables-view","fluid":"","tag":"section"}},[_c('material-card',{attrs:{"icon":"mdi-calendar-month","icon-small":"","color":"primary","title":"Bookings"}},[_c('v-card-text',[_c('v-col',{staticClass:"text-right ma-0 pa-0"},[_c('app-btn',{on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("mdi-account-plus-outline")]),_vm._v(" Add New Job ")],1)],1),_c('v-text-field',{staticClass:"ml-auto",staticStyle:{"max-width":"250px"},attrs:{"append-icon":"mdi-magnify","hide-details":"","single-line":"","label":"Search records"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-3"}),_c('v-data-table',{attrs:{"headers":_vm.new_headers,"items":_vm.bookings_data,"options":_vm.options,"server-items-length":_vm.totalRecords,"loading":_vm.loading,"items-per-page":5,"footer-props":{
          showFirstLastPage: true,
          'items-per-page-options': [5, 10, 15],
        },"show-expand":"","single-expand":"","expanded":_vm.expanded,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event},"item-expanded":function (data) { return _vm.getFareDetails(data); }},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
        var item = ref.item;
return [(_vm.getStatus(item.status))?_c('v-chip',{attrs:{"color":_vm.getStatus(item.status).color,"text-color":"white"}},[_vm._v(" "+_vm._s(_vm.getStatus(item.status).text))]):_vm._e()]}},{key:"top",fn:function(){return [_c('BookingDialog',{attrs:{"isOpen":_vm.dialog},on:{"closeDialog":function($event){_vm.dialog = false}}}),_c('v-dialog',{attrs:{"max-width":"950px"},model:{value:(_vm.dialogTripDetails),callback:function ($$v) {_vm.dialogTripDetails=$$v},expression:"dialogTripDetails"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Trip Details ")]),_c('v-card-text',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('PolyLineMap',{attrs:{"width":"650","height":"500","path":[
                        { lat: -33.7307419, lng: 150.9258165 },
                        { lat: -33.7623551, lng: 150.9781278 },
                        { lat: -33.7623551, lng: 150.9781278 },
                        { lat: -33.788357, lng: 151.0355983 } ]}})],1),_c('v-col',[_c('v-timeline',{attrs:{"align-top":"","dense":""}},_vm._l((_vm.selectedTrip.statuses),function(status,i){return _c('v-timeline-item',{key:i,attrs:{"color":"primary","small":""}},[_c('strong',[_vm._v(_vm._s(_vm.getStatus(status.status).text))]),_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.formatDate(status.time))+" ")])])}),1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogTripDetails = false}}},[_vm._v(" Close ")]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Are you sure you want to delete this booking? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)]},proxy:true},{key:"item.totalFares",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_vm._v(_vm._s("$" + item.totalFares))])]}},{key:"item.totalSpecialFares",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_vm._v(_vm._s("$" + item.totalSpecialFares))])]}},{key:"item.date",fn:function(ref){
                      var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDate(item.date)))])]}},{key:"item.pickedUpTime",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getpickedUpTime(item.statuses))+" ")]}},{key:"item.droppedOffTime",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDroppedOffTime(item.statuses))+" ")]}},{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [(_vm.getStatus(item.status).text === 'New')?_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"medium":"","color":"info"},on:{"click":function($event){return _vm.sendJob(item)}}},[_vm._v(" mdi-send ")]),_c('v-icon',{attrs:{"medium":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])],1):_c('div',[_c('v-icon',{attrs:{"medium":"","color":"info"},on:{"click":function($event){_vm.selectedTrip = Object.assign({}, item);
                _vm.dialogTripDetails = true;}}},[_vm._v(" mdi-map-search ")])],1)]}},{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var item = ref.item;
return [_c('td',{staticClass:"primary pa-1",attrs:{"colspan":headers.length}},[_c('v-data-table',{attrs:{"headers":_vm.fare_header,"items":item.specialFares,"footer-props":{
                showFirstLastPage: true,
              }},scopedSlots:_vm._u([{key:"item.fare",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s("$" + _vm.centsToDollor(item.fare))+" ")]}}],null,true)})],1)]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }