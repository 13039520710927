<template>
  <div
    id="map"
    :style="`width: ${width}px; height: ${height}px; border-radius: 15px; position: relative`"
  ></div>
</template>
<script>
export default {
  name: "PlyLineMap",
  props: {
    width: {
      type: String || Number,
      default: "500",
    },
    height: {
      type: String || Number,
      default: "500",
    },
    path: {
      type: Array,
      default: [],
    },
  },
  data: () => ({
    map: null,
  }),

  computed: {
    getMapCenter() {
      return this.$store.state.authUser.mapCenter;
    },
  },

  watch: {
    path: {
      handler(val) {
        if (Array.isArray(val) && val.length > 0) {
          this.addPolyLine(val);
        } else {
          this.addPolyLine([]);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initMap();
  },

  methods: {
    initMap() {
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: this.getMapCenter,
        zoom: 13,
        maxZoom: 20,
        minZoom: 3,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
        mapId: "b54202e65490cbc5",
      });
    },

    addPolyLine(path) {
      const polyline = new google.maps.Polyline({
        path: path,
        geodesic: false,
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });

      var bounds = new google.maps.LatLngBounds();
      polyline.getPath().forEach(function (e) {
        bounds.extend(e);
      });
      this.map.fitBounds(bounds);

      polyline.setMap(this.map);
    },
  },
};
</script>